

























































































































































import {Component, Watch} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import Selector from '@/components/Selector.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import NotificationsMixin from '@/components/mixins/Notifications';
import ChatInfoUser from '@/components/ChatInfoUser.vue';
import rfdc from 'rfdc';
import {settingsStore} from '@/store/modules/settings';
import {mixins} from "vue-class-component";
import {ArchiveSetting} from "@/domain/model/types";
import constants from '@/common/constants';
const clone = rfdc({proto: true})

@Component({
  name: 'Archiving',
  components: {VcSwitch, Selector, SettingsItem, ChatInfoUser}
})
export default class Notifications extends mixins(NotificationsMixin) {
  showDialogAddRecipientContact: boolean = false;

  autoArchive: ArchiveSetting  = {
    active: true,
    emails: [],

    // new
    isExportOpenCases: false,
    recurrenceType: 'Daily',
    recurrenceTime: constants.ARCHIVE_RECURRENCE_TIME
  }

  autoArchiveActive: boolean = false
  disabled: boolean = true
  receiverEmails: any[] = [{email: ''}]
  doubleEmail: any = {} 

  recTypes: any[] = [{name: 'Daily', value: 'Daily'}];
  recTimes: string[] = [
    '12:00 AM', 
    '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', 
    '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', 
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', 
    '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
  ];  

  @Watch('autoArchive', {deep: true, immediate: false})
  onAlertChanged(after, before) {
    this.checkDisabled()
  }

  checkDisabled() {
    if (!this.compareChange() && this.emailValidate() && this.autoArchive.emails && this.autoArchive.emails.length > 0) {
      this.disabled = false
      return
    }
    this.disabled = true
  }


  @Watch('autoArchiveActive')
  async onAutoArchiveActiveActiveChanged(after, _) {
    console.log('this.autoArchiveActive : ' + this.autoArchiveActive);
    if(this.autoArchive?.emails!.length > 0){
      await settingsStore.autoArchiveActiveChanged(after)
    }
  }

  @Watch('receiverEmails', {deep: true})
  onChangeEmails(after) {
    this.doubleEmail = {}
    this.doubleEmailCounter(after)
    this.autoArchive.emails = after.map(item => item.email).filter(item => item.length > 0)
  }


  doubleEmailCounter(items) {
    items.forEach(item => {
      const email = item.email ? item.email : null
      if (email) {
        if (this.doubleEmail.hasOwnProperty(email)) {
          this.doubleEmail[email] = this.doubleEmail[email] + 1
        } else {
          this.doubleEmail[email] = 1
        }
      }
    })
  }

  compareAutoArchive() {
    return JSON.stringify(this.autoArchive) === JSON.stringify(settingsStore.businessSettings?.autoArchive)
  }

  compareRecurrenceTime() {   
    return JSON.stringify(this.autoArchive.recurrenceTime) === JSON.stringify(settingsStore.businessSettings?.autoArchive.recurrenceTime)
  }

  compareChange() {
    return this.compareAutoArchive()
  }

  emailValidate() {
    const form: any = this.$refs.receiverEmailForm
    let validate = true
    this.$nextTick(() => {
      if (form) validate = form.validate()
    })
    return validate
  }

  validateDoubleEmail(v) {
    return !v || this.doubleEmail[v] <= 1 || 'Duplicate email address'
  }

  onClearEmail(index) {
    this.receiverEmails.splice(index, 1)
    if (this.receiverEmails.length < 1) {
      this.receiverEmails.push({email: ''})
    }
  }

  onAddEmail() {
    if (this.receiverEmails.length < 5) {
      this.receiverEmails.push({email: ''})
    }
  }

  async onSave() {  
    const form: any = this.$refs.receiverEmailForm
    if (form.validate()){
      // mail date
      if (!this.compareRecurrenceTime()) {        
        await settingsStore.updateAutoArchiveMailedDate();
      }

      // all settings
      if (!this.compareAutoArchive()) {   
        this.autoArchive.active = this.autoArchiveActive; 
        await settingsStore.changeAutoArchives(clone(this.autoArchive));
      }     

      this.showInfo('Archive Settings are changed')
      this.checkDisabled()
    }else{
      this.showIssue('Archive Settings not changed')
    }  
  }

  onDiscard() {
    this.init()
  }

  onAddRecipientContact() {
    this.showDialogAddRecipientContact = true
  }

  init() {
    this.autoArchive = clone(settingsStore.businessSettings?.autoArchive)
    this.autoArchiveActive = settingsStore.businessSettings?.autoArchive?.active
    if (Array.isArray(this.autoArchive?.emails) && this.autoArchive!.emails!.length > 0) {
      this.receiverEmails = this.autoArchive!.emails!.map(item => {
        return {email: item}
      }) || [{email: ''}]
      this.doubleEmailCounter(this.receiverEmails)
    }
  }

  created() {
    this.init()
  }
}
